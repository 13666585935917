import axios from '@/config/httpConfig'
// 获取报表的优化SQL语句
export function getCustomReportSql(data) {
    return axios.post('/report/getCustomReportSql', data)
}
// 验证并保存优化SQL
export function verificationCustomReportSql(data) {
    return axios.post('/report/verificationCustomReportSql', data)
}
// 优化后的报表详情查询
export function getOptimizationReportDataByDetail(data) {
    return axios.post('/report/getOptimizationReportDataByDetail', data)
}
// 获取报表详细信息
export function getReportDataByDetail(data) {
    return axios.post('/report/getReportDataByDetail', data)
}