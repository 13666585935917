<template>
  <div>
    <div class="custom-sql">
      <div class="custom-code">
        <div class="sql-left">
          <p class="title">原报表语句</p>
          <div class="code" ref="oldeditor"></div>
        </div>
        <div class="sql-right">
          <p class="title">请在此输入优化语句，并点击验证</p>
          <el-tabs class="code" v-model="activeName">
            <el-tab-pane label="查询优化语句" name="first">
              <div class="new-editor" ref="neweditor"></div>
            </el-tab-pane>
            <el-tab-pane label="执行结果" name="second">
              <div>{{ result }}</div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="custom-btn">
        <el-button size="mini" type="primary" @click="getReportDataByDetails"
          >执行</el-button
        >
        <el-button size="mini" type="primary" @click="verificationSQL"
          >验证</el-button
        >
      </div>
    </div>
    <div class="report-table">
      <!--  列表式报表：行分组等于0，列分组等于0 -->
      <!-- 汇总式：行分组大于0，列分组等于0 -->
      <!-- 矩阵式报表：行分组大于0，列分组大于0 -->

      <listTypeTable
        class="list_table"
        v-if="
          transversegroupList.length === 0 && lengthwaysgroupList.length === 0
        "
        v-loading="reportLoading"
        :element-loading-text="$t('label.report.loading')"
        element-loading-spinner="el-icon-loading"
        :tableData="tableData"
        :listCheckedFields="listCheckedFields"
        :expressionLine="expressionLine"
        :mapTaskRel="mapTaskRel"
        :chooseRowFileds="chooseRowFileds"
        :choosedColumnFileds="choosedColumnFileds"
        :groupIds="groupIds"
        :indexData="indexData"
        :dateGroupings="dateGroupings"
        :statisticsDatas="statisticsDatas"
        :sortDatas="sortDatas"
        :getCheckboxFiled="getCheckboxFiled"
        @sortingOrder="sortingOrder"
        @clickColOperation="clickColOperation"
        @deleteGroupRow="deleteGroupRow"
        @deleteColumn="deleteColumn"
      />

      <!-- 汇总式：行分组大于0，列分组等于0 -->
      <summaryTypeTable
        class="list_table"
        v-loading="reportLoading"
        :element-loading-text="$t('label.report.loading')"
        element-loading-spinner="el-icon-loading"
        v-if="
          transversegroupList.length > 0 && lengthwaysgroupList.length === 0
        "
        :transversegroupList="transversegroupList"
        :lengthwaysgroupList="lengthwaysgroupList"
        :showRefresh="showRefresh"
        :tableData="tableData"
        :listCheckedFields="listCheckedFields"
        :expressionLine="expressionLine"
        :expressionSummary="expressionSummary"
        :listAvgFields="listAvgFields"
        :listMaxFields="listMaxFields"
        :listMinFields="listMinFields"
        :gatherFieldList="gatherFieldList"
        :objectSpanMethod="objectSpanMethod"
        :mapTaskRel="mapTaskRel"
        :chooseRowFileds="chooseRowFileds"
        :choosedColumnFileds="choosedColumnFileds"
        :groupIds="groupIds"
        :indexData="indexData"
        :dateGroupings="dateGroupings"
        :statisticsDatas="statisticsDatas"
        :sortDatas="sortDatas"
        :getCheckboxFiled="getCheckboxFiled"
        @sortingOrder="sortingOrder"
        @clickColOperation="clickColOperation"
        @deleteGroupRow="deleteGroupRow"
        @deleteColumn="deleteColumn"
      />

      <!-- 矩阵式报表：行分组大于0，列分组大于0 -->
      <matrixTable
        class="list_table"
        v-loading="reportLoading"
        :element-loading-text="$t('label.report.loading')"
        element-loading-spinner="el-icon-loading"
        v-if="lengthwaysgroupList.length > 0 && transversegroupList.length > 0"
        :transversegroupList="transversegroupList"
        :rowColumnData="rowColumnData"
        :chooseColumnTypes="chooseColumnTypes"
        :lengthwaysgroupList="lengthwaysgroupList"
        :expressionSummary="expressionSummary"
        :isHavaMoreColumns="isHavaMoreColumns"
        :objectSpanMethod="objectSpanMethod"
        :mapTaskRel="mapTaskRel"
        :chooseRowFileds="chooseRowFileds"
        :choosedColumnFileds="choosedColumnFileds"
        :groupIds="groupIds"
        :indexData="indexData"
        :dateGroupings="dateGroupings"
        :statisticsDatas="statisticsDatas"
        :sortDatas="sortDatas"
        ref="matrixTableRef"
        :getCheckboxFiled="getCheckboxFiled"
        @sortingOrder="sortingOrder"
        @clickColOperation="clickColOperation"
        @deleteGroupRow="deleteGroupRow"
        @deleteColumn="deleteColumn"
      />
    </div>
  </div>
</template>

<script>
import * as ReportObjectApi from "./api"; // 请求api
import { mergeTableRow, mergeTableCoulmn } from "@/config/table/mergeTableRow";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api.js";
import "monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution"; // 代码高亮
import "monaco-editor/esm/vs/editor/contrib/find/findController.js"; // 引入查找控件
import listTypeTable from "../components/listTypeTable.vue";
import summaryTypeTable from "../components/summaryTypeTable.vue";
import matrixTable from "../components/matrixTable.vue";
export default {
  components: {
    summaryTypeTable,
    listTypeTable,
    matrixTable,
  },
  data() {
    return {
      oldeditor: null, // 编辑器对象
      neweditor: null, // 编辑器对象
      editorOpertions: {
        language: "json",
        // 其他需要配置的属性
        minimap: {
          enabled: false, // 编辑器右侧的缩略图
        },
        formatOnPaste: true,
        wordWrap: "on", // 自动换行，注意大小写
        wrappingIndent: "indent",
        suggest: {
          showIcons: false,
        },
        folding: true, // 是否启用代码折叠
      }, // 编辑器属性
      activeName: "first", // 选项卡
      result: "", //验证结果
      reporttype: "", //报表类型
      tableData: [], // 列表表格、汇总表格
      listCheckedFields: [], // 选择的报表列表头字段集合
      reportLoading: false, //报表数据的loading
      allSelectFileds: [], // 报表的数据源对象
      allFiledDatas: [], // 可筛选的下拉内容的即字段列表
      groupIds: [], // 所有分组字段id集合
      chooseColumnTypes: [], // 列分组的字段内容
      transversegroupList: [],
      lengthwaysgroupList: [],
      chooseRowFileds: [], // 行分组字段集合
      choosedColumnFileds: [], // 列分组字段集合
      rowColumnData: [], // 矩阵式表格，表格内容
      timeRanges: [], // 选择的时间范围字段信息
      choosedTimeRanges: {}, // 选择的时间范围筛选条件
      listCheckedFieldsArr: [], // 本地选择的报表列字段集合
      allObjects: {}, // 数据源对象
      mainobjectcolumnid: [], // 主对象id集合
      totalrecords: 0, // 记录数
      matrixCounts: 0, // 矩阵式报表的统计数
      loading: true, // 表格loading，一进来就加载
      loadingDetail: false, // 矩阵式报表的详表loading
      summaryTree: [], // 行级汇总公式左侧树数据
      expressionLine: [], // 行级公式
      expressionSummary: [], // 汇总公式
      conditionVals:
        '{"data":[],"filter":"","relatedObjIdC":"","relatedObjIdD":""}', // 过滤条件
      indexData: {}, // clickImg事件传入子组件数据
      transversedatetypeone: "month", // 一级行分组的日期分组依据
      transversedatetypetwo: "month", // 二级行分组的日期分组依据
      transversedatetypethree: "month", // 三级行分组的日期分组依据
      lengthwaysdatetypeone: "month", // 一级列分组的日期分组依据
      lengthwaysdatetypetwo: "month", // 二级列分组的日期分组依据
      gatherfieldname: [], // 求和字段id集合
      avgfield: [], // 平均值字段id集合
      maxfield: [], // 最大值字段id集合
      minfield: [], // 最小值字段id集合
      gatherFieldList: [], // 求和字段集合
      listAvgFields: [], // 平均值字段集合
      listMaxFields: [], // 最大值字段集合
      listMinFields: [], // 最小值字段集合
      showRefresh: false, // 是否显示点击刷新按钮
      allObjectDatas: [], // 表头业务对象数据
      isApply: false, // 时间范围筛选器是否在被使用
      runTo: false, // 是否点击运行
      mapTaskRel: {}, // 需要根据api值获取数据
      runShowDataTo: false, // 运行状态下是否仅显示的2000条数据
      pageSource: "", // 页面上一页来源，主要为了区分是否首次新建报表
      isHavaMoreColumns: false, // 矩阵式报表，如果列超出限制，需要在合计前加一列，全部数据展示为...
      tempId: "", // 用于存储其他页面传来的报表id（因为第二次请求需要清空id）
      tempReporttypecustomid: "", // 用于存储其他页面传来的报表Reporttypecustomid，在最后保存时传过去
      temName: "", // 报表名称
      dateGroupings: {
        // 获取数据传给子组件日期分组依据
        transversedatetypeone: "",
        transversedatetypetwo: "",
        transversedatetypethree: "",
        lengthwaysdatetypeone: "",
        lengthwaysdatetypetwo: "",
      },
      statisticsDatas: {
        // 统计数据信息
        gatherfieldname: "",
        avgfield: "",
        maxfield: "",
        minfield: "",
      },
      sortDatas: {
        // 排序字段信息
        orderfieldapi: "",
        orderfieldtype: "",
        ordertype: "",
      },
      reportNameNote: {
        // 另存为时的报表名称和备注
        reportId: "",
        reportLabel: "",
        description: "",
        reportfolderid: "",
      },
      isFirstRequest: true, // 是否为报表数据接口的第一次请求，默认值为true
    };
  },
  mounted() {
    this.initNeweditor();
    this.getCustomReportSql();
    // this.getReportDataByDetails();
  },
  methods: {
    /** 获取报表的优化SQL语句 */
    getCustomReportSql() {
      let id = this.$route.params.reportId;
      let m = this.$route.params.m;
      ReportObjectApi.getCustomReportSql({
        id: id,
        m: m,
      }).then((res) => {
        if (res.data) {
          this.initOldeditor(JSON.stringify(res.data)); //SQL
          this.reporttype = res.data.reportType; //类型
        }
      });
    },
    /** 验证并保存优化SQL */
    verificationSQL() {
      ReportObjectApi.verificationCustomReportSql({
        reporttype: this.reporttype,
        id: this.$route.params.reportId,
        m: this.$route.params.m,
        optimizationSql: this.neweditor.getValue(),
      }).then((res) => {
        if (1 == res.returnCode) {
          this.result = res.returnInfo;
          this.activeName = "second";
        }
        if (true == res.result) {
          this.getReportDataByDetails("isVerify");
        }
      });
    },
    /** 报表详情数据 */
    async getReportDataByDetails(data) {
      data = data || "";
      this.reportLoading = true;

      let id = this.$route.params.reportId;

      if (data == "isVerify") {
        var dataObject =
          await ReportObjectApi.getOptimizationReportDataByDetail({
            id: id,
            isshowdetail: "true",
          });
      } else {
        await ReportObjectApi.getReportDataByDetail({
          id: id,
          isshowdetail: "true",
        });
      }
      // 清空数据：表格、行列分组
      this.tableData = [];
      this.rowColumnData = [];
      this.listCheckedFields = [];
      this.listCheckedFieldsArr = [];
      this.transversegroupList = [];
      this.lengthwaysgroupList = [];
      this.chooseRowFileds = [];
      this.choosedColumnFileds = [];
      this.mapTaskRel = {};

      // 定义变量reportInfo，reportTC
      let reportInfoData = dataObject.data.reportInfo;
      let reportTC = dataObject.data.reportInfo.reportTC;
      // 汇总式报表行列分组的集合字段正好跟矩阵式报表的相反，先定义一下
      this.transversegroupList = reportInfoData.transversegroupList; //获取已选择的行分组
      this.lengthwaysgroupList = reportInfoData.lengthwaysgroupList; //获取已选择的列分组
      this.loading = false; // 在图表接口也返回之后再取消加载，要不报表会出现暂时的暂无数据

      // 带有报表id的第一次请求，获取报表数据
      if (this.tempId) {
        document.title = this.$setTitle(`${reportInfoData.name}`); // 设置 title 标题
        this.temName = reportInfoData.name;
        // 在左侧菜单中显示报表所选择的数据源
        this.allObjectDatas = [];
        this.allObjectDatas.push(
          reportTC.objectALabel,
          reportTC.objectBLabel,
          reportTC.objectCLabel,
          reportTC.objectDLabel
        );
        // 存储reporttypecustomi，留作保存的时候传过去
        this.tempReporttypecustomid = reportInfoData.reporttypecustomid;
        // 获取另存为时的报表名称和备注
        this.reportNameNote = {
          reportId: reportInfoData.id,
          reportLabel: reportInfoData.name,
          description: reportInfoData.description,
          reportfolderid: reportInfoData.reportfolderid,
        };
        // 将后台返回的对应的所属数据源信息保存，作为下次 请求使用
        this.allObjects = {
          objecta: reportTC.objecta,
          objectb: reportTC.objectb,
          optionb: reportTC.optionb,
          bfindid: reportTC.bfindid,
          objectc: reportTC.objectc,
          optionc: reportTC.optionc,
          cfindid: reportTC.cfindid,
          objectd: reportTC.objectd,
          optiond: reportTC.optiond,
          dfindid: reportTC.dfindid,
        };
      }
      // 每次进行操作都会重新请求报表接口获取数据，进行数据回显同步
      // 将后台返回的mainobjectcolumnid选择的组行分组赋值给当前的组件中，到运行页时作为参数使用
      this.mainobjectcolumnid = reportInfoData.mainobjectcolumnid
        ? reportInfoData.mainobjectcolumnid.split(",")
        : [];
      // 获取筛选条件字段列表
      this.allFiledDatas = [];
      this.allSelectFileds = [];
      // 对象A
      if (reportTC.filteredListObjectAField.length > 0) {
        reportTC.filteredListObjectAField.map((item) => {
          this.allFiledDatas.push(item);
        });
        this.allSelectFileds.push({
          id: reportTC.objecta,
          label_name: reportTC.objectALabel,
          fieldList: reportTC.filteredListObjectAField,
        });
      }
      // 对象B
      if (reportTC.filteredListObjectBField.length > 0) {
        reportTC.filteredListObjectBField.map((item) => {
          this.allFiledDatas.push(item);
        });
        this.allSelectFileds.push({
          id: reportTC.objectb,
          label_name: reportTC.objectBLabel,
          fieldList: reportTC.filteredListObjectBField,
        });
      }
      // 对象C
      if (reportTC.filteredListObjectCField.length > 0) {
        reportTC.filteredListObjectCField.map((item) => {
          this.allFiledDatas.push(item);
        });
        this.allSelectFileds.push({
          id: reportTC.objectc,
          label_name: reportTC.objectCLabel,
          fieldList: reportTC.filteredListObjectCField,
        });
      }
      // 对象D
      if (reportTC.filteredListObjectDField.length > 0) {
        reportTC.filteredListObjectDField.map((item) => {
          this.allFiledDatas.push(item);
        });
        this.allSelectFileds.push({
          id: reportTC.objectd,
          label_name: reportTC.objectDLabel,
          fieldList: reportTC.filteredListObjectDField,
        });
      }
      if (
        reportInfoData.transversegroupList.length > 0 &&
        reportInfoData.lengthwaysgroupList.length === 0
      ) {
        // 汇总式
        // 选择的行分组字段集合
        reportInfoData.transversegroupList.map((item) => {
          this.chooseRowFileds.push({
            apiname: item.schemefield_name,
            fieldtype: item.schemefieldType,
            id: item.id,
            labelname: item.label_name,
            objid: item.schemetableId,
          });
        });
        // 日期数据
        this.transversedatetypeone = reportInfoData.transversedatetypeone;
        this.transversedatetypetwo = reportInfoData.transversedatetypetwo;
        this.transversedatetypethree = reportInfoData.transversedatetypethree;
        this.lengthwaysdatetypeone = reportInfoData.lengthwaysdatetypeone;
        this.lengthwaysdatetypetwo = reportInfoData.lengthwaysdatetypetwo;
        // 获取时间分组依据类型传给子组件
        this.dateGroupings = {
          transversedatetypeone: reportInfoData.transversedatetypeone,
          transversedatetypetwo: reportInfoData.transversedatetypetwo,
          transversedatetypethree: reportInfoData.transversedatetypethree,
          lengthwaysdatetypeone: reportInfoData.lengthwaysdatetypeone,
          lengthwaysdatetypetwo: reportInfoData.lengthwaysdatetypetwo,
        };
      } else if (
        // 矩阵式
        reportInfoData.lengthwaysgroupList.length > 0 &&
        reportInfoData.transversegroupList.length > 0
      ) {
        // 选择的行列分组字段集合
        reportInfoData.lengthwaysgroupList.map((item) => {
          this.chooseRowFileds.push({
            apiname: item.schemefield_name,
            fieldtype: item.schemefieldType,
            id: item.id,
            labelname: item.labelName,
            objid: item.schemetableId,
          });
        });
        this.choosedColumnFileds = JSON.parse(
          JSON.stringify(reportInfoData.transversegroupList)
        );
        // 日期数据
        this.transversedatetypeone = reportInfoData.lengthwaysdatetypeone;
        this.transversedatetypetwo = reportInfoData.lengthwaysdatetypetwo;
        this.transversedatetypethree = reportInfoData.transversedatetypethree;
        this.lengthwaysdatetypeone = reportInfoData.transversedatetypeone;
        this.lengthwaysdatetypetwo = reportInfoData.transversedatetypetwo;
        // 获取时间分组依据类型传给子组件
        this.dateGroupings = {
          transversedatetypeone: reportInfoData.lengthwaysdatetypeone,
          transversedatetypetwo: reportInfoData.lengthwaysdatetypetwo,
          transversedatetypethree: reportInfoData.transversedatetypethree,
          lengthwaysdatetypeone: reportInfoData.transversedatetypeone,
          lengthwaysdatetypetwo: reportInfoData.transversedatetypetwo,
        };
      }
      // 获取排序
      this.sortDatas = {
        orderfieldapi: reportInfoData.orderfieldapi || "",
        orderfieldtype: reportInfoData.orderfieldtype || "",
        ordertype: reportInfoData.ordertype || "",
      };
      // 统计的字段id集合
      this.gatherfieldname = reportInfoData.gatherfieldname
        ? reportInfoData.gatherfieldname.split(",")
        : [];
      this.avgfield = reportInfoData.avgfield
        ? reportInfoData.avgfield.split(",")
        : [];
      this.maxfield = reportInfoData.maxfield
        ? reportInfoData.maxfield.split(",")
        : [];
      this.minfield = reportInfoData.minfield
        ? reportInfoData.minfield.split(",")
        : [];
      // 设置行计数按钮，判断是否要禁用
      let e = {
        gatherfieldname: this.gatherfieldname,
        avgfield: this.avgfield,
        maxfield: this.maxfield,
        minfield: this.minfield,
      };
      this.$store.commit("setLineCountButtDisabled", e);
      // 统计的字段集合
      this.gatherFieldList = reportInfoData.gatherFieldList
        ? reportInfoData.gatherFieldList
        : [];
      this.listAvgFields = reportInfoData.listAvgFields
        ? reportInfoData.listAvgFields
        : [];
      this.listMaxFields = reportInfoData.listMaxFields
        ? reportInfoData.listMaxFields
        : [];
      this.listMinFields = reportInfoData.listMinFields
        ? reportInfoData.listMinFields
        : [];
      // 获取统计数据传给子组件
      this.statisticsDatas = {
        gatherfieldname: reportInfoData.gatherfieldname || "",
        avgfield: reportInfoData.avgfield || "",
        maxfield: reportInfoData.maxfield || "",
        minfield: reportInfoData.minfield || "",
      };
      // 获取行级汇总公式左侧树数据
      this.summaryTree = [];
      let tempArr = [];
      tempArr.push(
        {
          labelName: reportTC.objectALabel,
          fieldList: reportTC.filteredListObjectAField,
        },
        {
          labelName: reportTC.objectBLabel,
          fieldList: reportTC.filteredListObjectBField,
        },
        {
          labelName: reportTC.objectCLabel,
          fieldList: reportTC.filteredListObjectCField,
        },
        {
          labelName: reportTC.objectDLabel,
          fieldList: reportTC.filteredListObjectDField,
        }
      );
      tempArr = tempArr.filter((item) => {
        if (item.labelName != "") {
          item.fieldList = item.fieldList.filter((itemx) => {
            if (itemx.schemefieldType === "Z") {
              if (
                itemx.expressionType === "N" ||
                itemx.expressionType === "p" ||
                itemx.expressionType === "c"
              ) {
                return itemx;
              }
            } else {
              if (
                itemx.schemefieldType === "N" ||
                itemx.schemefieldType === "p" ||
                itemx.schemefieldType === "c"
              ) {
                return itemx;
              }
            }
          });
          // 如果字段不存在数值类型的就在行级公式中的菜单中不显示菜单
          if (item.fieldList.length > 0) {
            return item;
          }
        }
      });
      this.summaryTree = tempArr;
      // 获取已应用的行级公式和汇总公式
      if (reportInfoData.listReportExpression != undefined) {
        // 行级公式
        this.expressionLine = reportInfoData.listReportExpression.filter(
          (item) => item.type === "L"
        );
        // 汇总公式
        this.expressionSummary = reportInfoData.listReportExpression.filter(
          (item) => item.type === "Z"
        );
      } else {
        this.expressionLine = [];
        this.expressionSummary = [];
      }
      this.timeRanges = reportInfoData.listDateField; //获取时间范围下拉列表
      // 回显已选择的时间范围字段
      if (reportInfoData.datecon) {
        this.choosedTimeRanges = this.timeRanges.find(
          (item) => item.id == reportInfoData.datecon
        );
        this.isApply = true;
      }
      // this.$refs.filterSetRef.form.person = reportInfoData.scope; // 向我展示回显
      this.mapTaskRel = dataObject.data.mapTaskRel;
      // 运行状态，列表式报表仅展示2000条
      if (this.runTo) {
        if (
          dataObject.data.listReportData &&
          dataObject.data.listReportData.length > 0
        ) {
          this.runShowDataTo = true;
        }
      }
      this.totalrecords = dataObject.data.dataSize; // 记录数
      if (dataObject.data.ListTotal && dataObject.data.ListTotal.length > 0) {
        dataObject.data.ListTotal.find((item) => {
          if (item.schemefieldnamealias === "totalrecord") {
            return (this.matrixCounts = item.total);
          }
        });
      } else {
        this.matrixCounts = "";
      }
      // 矩阵式报表的统计数
      this.isHavaMoreColumns = dataObject.data.isHavaMoreColumns
        ? JSON.parse(dataObject.data.isHavaMoreColumns)
        : false; // 矩阵式报表上面的统计表是否还有列未显示出来
      this.listCheckedFieldsArr = JSON.parse(
        JSON.stringify(reportInfoData.listCheckedFields)
      ); // 获取报表列数据
      this.listCheckedFields = JSON.parse(
        JSON.stringify(reportInfoData.listCheckedFields)
      ); //获取表头数据
      this.groupIds = reportInfoData.groupIds.split(","); //所选行分组字段
      // 分组的后的表格数据的模块（第一个表格）
      this.tableData =
        dataObject.data.listReportDataLineAsc ||
        dataObject.data.listReportData ||
        reportInfoData.listTranGroupDataBean; //获取行列表
      this.reportLoading = false;
      // 给报表赋值
      if (reportInfoData.groupList.length > 0) {
        //有分组
        if (
          //有行分组
          reportInfoData.transversegroupone != "" &&
          (reportInfoData.lengthwaysgroupone === undefined ||
            reportInfoData.lengthwaysgroupone === "")
        ) {
          if (JSON.stringify(dataObject.data.mapTotal) !== "{}") {
            dataObject.data.mapTotal.level01 = "maxtotal";
            this.tableData.push(dataObject.data.mapTotal);
          }
          let mergeRowArr = [];
          reportInfoData.transversegroupList.map((item) => {
            mergeRowArr.push(item.schemefield_name_alias);
          });
          this.tableData = mergeTableRow(this.tableData, mergeRowArr);
        } else if (reportInfoData.transversegroupList.length > 0) {
          //有列分组
          this.rowColumnData = dataObject.data.listMatData;
          if (dataObject.data.listMatData.length > 0) {
            let dataTotal = {
              listLengthData: dataObject.data.ListTotal,
              mapLengthGroup: {
                detailOrSubtotal: "maxtotal",
              },
            };
            this.rowColumnData.push(dataTotal);
          }
          this.chooseColumnTypes = reportInfoData.listTranGroupDataBean;
          let mergeRowArr = [];
          reportInfoData.lengthwaysgroupList.map((item) => {
            mergeRowArr.push(item.schemefield_name_alias);
          });
          this.rowColumnData = mergeTableCoulmn(
            this.rowColumnData,
            mergeRowArr
          );
        }
      } else {
        // 没有分组
        this.tableData =
          dataObject.data.listReportDataLineAsc ||
          dataObject.data.listReportData ||
          reportInfoData.listTranGroupDataBean;
        if (this.tableData.length > 0) {
          dataObject.data.mapTotal.level01 = "maxtotal";
          dataObject.data.mapTotal.totalrecord = this.tableData.length;
          this.tableData.push(dataObject.data.mapTotal);
        }
      }
      this.tempId = ""; // 清空第一次报表列表进来时传递的id
      this.pageSource = ""; // 清空第一次新建报表设置的来源页面值
      this.isFirstRequest = false; // 第一次请求完成，不论为新增还是已有报表id
    },
    // 判断字段是否为复选框类型，且获取对应的value
    getCheckboxFiled(item, value) {
      // 定义变量：字段是否为复选框类型，复选框的value
      let data = {
        isCheckboxFiled: false,
        checkboxFiledValue: false,
      };
      if (item.schemefieldType !== "B") return data;
      data.isCheckboxFiled = true; // 属于复选框类型
      if (value === "true") {
        data.checkboxFiledValue = true;
      }
      return data;
    },
    // 合并行列
    objectSpanMethod({ row, column }) {
      const span = column["property"] + "-span";
      if (row[span]) {
        return row[span];
      }
    },
    // 排序：升序、降序
    sortingOrder(item, type, tableType) {
      this.sortDatasObject = {
        orderfieldapi: item.schemefield_name_alias,
        orderfieldtype: item.schemefieldType,
        ordertype: type,
      };
      if (tableType) {
        this.getReportDetail(); // 矩阵式报表的详表排序不需保存，实时的就可。
      } else {
        // 其他类型表的排序
        this.getReportDataByDetails();
      }
    },
    // 日期分组依据
    clickColOperation(data) {
      let rowArr = this.chooseRowFileds;
      let columnArr = this.choosedColumnFileds;
      if (rowArr.some((item) => item.id == this.indexData.id)) {
        //属于行分组
        let index = rowArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            this.transversedatetypeone = data;
            break;
          case 1:
            this.transversedatetypetwo = data;
            break;
          case 2:
            this.transversedatetypethree = data;
            break;
        }
      } else {
        let index = columnArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            this.lengthwaysdatetypeone = data;
            break;
          case 1:
            this.lengthwaysdatetypetwo = data;
            break;
        }
      }
      this.getReportDataByDetails();
    },
    // 删除组行
    deleteGroupRow() {
      let rowArr = this.chooseRowFileds;
      let columnArr = this.choosedColumnFileds;
      if (rowArr.some((item) => item.id == this.indexData.id)) {
        let index = rowArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            rowArr.splice(index, 1);
            break;
          case 1:
            rowArr.splice(index, 1);
            break;
          case 2:
            rowArr.splice(index, 1);
            break;
        }
      } else {
        let index = columnArr.findIndex((item) => item.id == this.indexData.id);
        switch (index) {
          case 0:
            rowArr.splice(index, 1);
            break;
          case 1:
            rowArr.splice(index, 1);
            break;
        }
      }
      this.getReportDataByDetails();
    },
    // 删除列
    deleteColumn(index) {
      this.deleteReportColData(this.indexData.id, index);
    },
    // 删除已选报表列数据
    deleteReportColData(id, index) {
      this.listCheckedFieldsArr.splice(index, 1);
      let indexs = this.mainobjectcolumnid.findIndex((item) => item === id);
      this.mainobjectcolumnid.splice(indexs, 1);
      // 清除计算的总和、平均值、最大值、最小值
      if (this.gatherfieldname.indexOf(id) > -1) {
        let index = this.gatherfieldname.findIndex((item) => item === id);
        this.gatherfieldname.splice(index, 1);
      }
      if (this.avgfield.indexOf(id) > -1) {
        let index = this.avgfield.findIndex((item) => item === id);
        this.avgfield.splice(index, 1);
      }
      if (this.maxfield.indexOf(id) > -1) {
        let index = this.maxfield.findIndex((item) => item === id);
        this.maxfield.splice(index, 1);
      }
      if (this.minfield.indexOf(id) > -1) {
        let index = this.minfield.findIndex((item) => item === id);
        this.minfield.splice(index, 1);
      }
      this.getReportDataByDetails();
    },
    /** 原报表语句初始化 */
    initOldeditor(code) {
      if (this.oldeditor == null) {
        this.oldeditor = monaco.editor.create(this.$refs.oldeditor, {
          theme: "vs-dark",
          ...this.editorOpertions,
          readOnly: true, // 是否可编辑 // 是否为只读模式
          automaticLayout: true, // 自动布局
        });
        this.oldeditor.setValue(code);
      }
    },
    /** 优化语句初始化 */
    initNeweditor() {
      if (this.neweditor == null) {
        this.neweditor = monaco.editor.create(this.$refs.neweditor, {
          theme: "vs",
          ...this.editorOpertions,
        });
        let data = "//请于原报表语句的结构保持一致";
        this.neweditor.setValue(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-sql {
  background: #fff;
  padding: 10px;
  margin: 10px;
  overflow: hidden;
  .custom-code {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    div {
      flex: 1;
      .title {
        font-weight: bold;
        font-size: 16px;
      }
      .code {
        width: 100%;
        height: 340px;
        padding-right: 10px;
        ::v-deep .el-tabs__header {
          margin: 0 0 0 0;
        }
        .new-editor {
          height: 300px;
        }
      }
    }
  }
  .custom-btn {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
.list_table {
  height: 200px;
}
// 表头一行展示
::v-deep .report_header {
  // padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
  .el-icon-right,
  .el-icon-back,
  .el-icon-top,
  .el-icon-bottom {
    cursor: pointer;
    margin-left: 5px;
    color: #999999;
    font-size: 14px;
    font-weight: bold;
  }
}
</style>