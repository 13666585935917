/**
 * 改良后（不相邻的数据相互不受影响）
 * table合并行通用 */
export function mergeTableRow(data, merge) {
  if (!merge || merge.length === 0) {
    return data
  }
  merge.forEach((m, mIndex, mArr) => {
    const mList = {}
    data = data.map((v, index, arr) => {
      v[m] = v[m] === '' ? null : v[m]; // 将空串均转换为null
      const rowVal = v[m];
      if (mList[rowVal] && mList[rowVal].newIndex === index) {
        // 合并对应行分组中不属于小计的对应行
        if (v.level01 !== 'a' && mIndex === 0) {
          mList[rowVal]['num']++
          mList[rowVal]['newIndex']++
          data[mList[rowVal]['index']][m + '-span'].rowspan++
          v[m + '-span'] = {
            rowspan: 0,
            colspan: 0
          }
        } else if (v.level01 !== 'b' && v.level01 !== 'maxtotal' && mIndex === 1 &&
          v[mArr[0]] === arr[index - 1][mArr[0]]) {
          mList[rowVal]['num']++
          mList[rowVal]['newIndex']++
          data[mList[rowVal]['index']][m + '-span'].rowspan++
          v[m + '-span'] = {
            rowspan: 0,
            colspan: 0
          }
        } else if (v.level01 !== 'c' && v.level01 === [v + 1].level01 && mIndex === 2) {
          mList[rowVal]['num']++
          mList[rowVal]['newIndex']++
          data[mList[rowVal]['index']][m + '-span'].rowspan++
          v[m + '-span'] = {
            rowspan: 0,
            colspan: 0
          }
        } else {
          v[m + '-span'] = {
            rowspan: 1,
            colspan: 1
          }
        }
      } else {
        mList[rowVal] = { num: 1, index: index, newIndex: index + 1 }
        v[m + '-span'] = {
          rowspan: 1,
          colspan: 1
        }
      }
      return v
    })
  })
  return data
}

// 当有列分组的行分组合并
/**
 * 改良后（不相邻的数据相互不受影响）
 *  table合并行通用 */
export function mergeTableCoulmn(data, merge) {
  if (!merge || merge.length === 0) {
    return data
  }
  merge.forEach((m, mIndex) => {
    const mList = {}
    data = data.map((v, index, arr) => {
      const rowVal = v.mapLengthGroup[m];
      if (mList[rowVal] && mList[rowVal].newIndex === index && v.mapLengthGroup.detailOrSubtotal !== 'maxtotal') {
        mList[rowVal]['num']++
        mList[rowVal]['newIndex']++
        data[mList[rowVal]['index']][m + '-span'].rowspan++
        v[m + '-span'] = {
          rowspan: 0,
          colspan: 0
        }
      } else {
        mList[rowVal] = { num: 1, index: index, newIndex: index + 1 }
        v[m + '-span'] = {
          rowspan: 1,
          colspan: 1
        }
      }
      return v
    })
  })
  return data
}